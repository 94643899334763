import { breakpoints } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import { orange } from 'src/styles/newColors'
import styled from 'styled-components'

export const Card = styled.div`
  border-radius: 16px 16px 0px 0px;
  background-color: ${grayscale[100]};
  padding: 16px 24px;
  min-height: 240px;
  display: flex;
  flex-direction: column;

  & h3 {
    font-weight: 500;
  }

  & .card-header__category {
    background-color: ${orange.extra};
    font-size: 12px;
    color: ${grayscale[100]};
    font-family: 'Sora', Helvetica, sans-serif;
    border-radius: 4px;
    width: 150px;
    font-weight: 700;
    padding: 0 2px;
    text-align: center;
  }

  & .card-header__date {
    font-family: 'Sora', Helvetica, sans-serif;
    font-size: 12px;
    color: ${grayscale[300]};
  }

  & .card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  & .card-body__text {
    color: ${grayscale[400]};
    overflow: hidden;
    width: 100%;
    flex-grow: 1;
    display: block;
  }

  & .card-link {
    width: 100%;
    display: flex;
    justify-content: end;
    flex-grow: 0;
  }

  @media (min-width: ${breakpoints.md}) {
    min-height: 302px;
    max-width: 216px;
  }

  @media (min-width: ${breakpoints.lg}) {
    min-height: 264px;
    max-width: unset;
  }
`
