import { breakpoints, device } from 'src/styles/breakpoints';
import { gray, grayscale, green, orange, red, white } from 'src/styles/colors';
import styled, { css } from 'styled-components';

type StyledValueDescription = {
  isError: boolean;
}

type BoxSimulador = {
  isPj?: boolean;
}

export const BoxSimulador = styled.div`
  position: relative;
  width: 100%;
  border-radius: 24px;
  overflow: hidden;
  filter: drop-shadow(0px 5.10777px 5.10777px rgba(0, 0, 0, 0.25));

  @media (min-width: ${breakpoints.md}) {
    width: 320px;
    border-radius: 35px;
  }
  @media (min-width: ${breakpoints.lg}) {
    width: 454px;
    border-radius: 50px;
    max-width: 100% ;
  }

  .options {
    background: ${white};
    border-radius: 8px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
    position: relative;
    width: 100%;
    border-radius: 24px;
    overflow: hidden;
    height: 100%;
    padding: 21px 30px;

    @media ${device.desktopLG} {
      padding: 30px 50px;
    }

    .range-slider {
      box-sizing: border-box;
      display: inline-block;
      position: relative;
      width: 100%;
      z-index: 0;

      & > input {
        cursor: grab;
        opacity: 0;
        position: relative;
        width: 100%;
        z-index: 5;

        &::-webkit-slider-thumb {
          appearance: none;
          border-radius: 10px;
          height: 30px;
          width: 50px;
          position: relative;
          z-index: 100;
        }
      }

      & > span.slider-container {
        box-sizing: border-box;
        display: inline-block;
        left: -8px;
        right: 30px;
        position: absolute;
        z-index: 3;

        & > span.bar {
          background-color: ${gray[500]};
          border-radius: 10px;
          display: inline-block;
          left: 10px;
          height: 8px;
          overflow: hidden;
          position: absolute;
          right: -30px;
          top: 10px;
          z-index: 1;
          & > span {

            background: ${(props: BoxSimulador) => props.isPj ? 'linear-gradient(2.37deg, #409B97 0%,  #409B97 100%)' : `linear-gradient(2.37deg, ${orange.dark} 0%, ${orange.base} 100%)`};
            display: block;
            float: left;
            height: 11px;
          }
        }
        & > span.bar-btn {
          display: inline-block;
          left: 0;
          position: absolute;
          top: -62px;

          &:after {
            background-color: ${white};
            border: 0.5px solid rgba(0, 0, 0, 0.1);
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1),
              0px 3px 3px rgba(0, 0, 0, 0.05), 0px 3px 1px rgba(0, 0, 0, 0.05);
            border-radius: 20px;
            content: "";
            height: 22px;
            left: 10px;
            position: absolute;
            top: 65px;
            width: 22px;
            z-index: 3;
          }
        }
      }
    }
  }

  .buttonSend {
    width: 100%;
    height: 40px;
    color: ${white};
    background: ${(props: BoxSimulador) => props.isPj ? green[500] : orange.extra};
    border-radius: 8px;
    border: none;
    outline: none;
    font-size: 14px;
    line-height: 17px;

    &:focus {
      outline: none;
    }

    &[disabled] {
      cursor: default;
      background: ${grayscale[300]};
    }

    @media ${device.tablet} {
      height: 40px;
      font-weight: normal;
    }

    @media ${device.desktopLG} {
      height: 59px;
    }
  }
`

export const ValueWrapper = styled.div`
  border-bottom: 1px solid ${orange.extra};

  @media ${device.tablet} {
    align-items: baseline;
  }
  @media ${device.desktopLG} {
    padding-bottom: 8px;
  }
`

export const Cifra = styled.span<StyledValueDescription>`
  font-family: "Sora", Helvetica, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: ${grayscale[500]};

  @media ${device.desktopLG} {
    font-size: 24px;
    line-height: 30px;
  }

  ${({ isError }: StyledValueDescription) => isError && css`
    color: ${red[500]} !important;
  `}
`

export const InputValue = styled.input<StyledValueDescription>`
  border: none;
  font-family: "Sora", Helvetica, sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  width: 100%;
  color: ${orange.extra};

  &:focus {
    outline: none;
  }

  @media ${device.desktopLG} {
    font-size: 32px;
    line-height: 40px;
  }

  ${({ isError }: StyledValueDescription) => isError && css`
    color: ${red[500]}!important;
  `}
`

export const ValueDescription = styled.p<StyledValueDescription>`
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;
  margin-bottom: 24px;
  color: ${grayscale[400]};
  @media ${device.desktopLG} {
    font-size: 14px;
    line-height: 17px;
  }
  ${({ isError }: StyledValueDescription) => isError && css`
    color: ${red[500]} !important;
  `}
`
