import React from 'react'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { InterP } from 'src/components/Text/BodyText'
import { H2Citrina } from 'src/components/Text/Headings'
import { orange } from 'src/styles/colors'
import styled from 'styled-components'
import { NoDecorationLink } from '../../../style'
import { Button } from '@interco/inter-ui/components/Button'
import { HomeEquitySectionsProps } from '../../..'
import { breakpoints } from 'src/styles/breakpoints'

const CardWrapper = styled.div`
  border-radius: 16px;
  min-height: 438px;
  max-width: 265px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 24px 16px;
  background-color: ${orange.extra};
  box-shadow: 0px 16px 32px 2px #16161614;
  * {
    color: #fff;
  }

  ul, li {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style: none;
  }

  & .list-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 14px;
    svg {
      height: 24px;
      width: 24px;
      margin-right: 16px;
    }
  }

  & .btn--white {
    background: #fff;
    color: ${orange.extra};
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: 60%;
    flex-grow: 1;
    margin-right: 32px;
    min-height: unset;
    height: fit-content;
    padding: 30px 40px;
  }

  @media (min-width: ${breakpoints.lg}) {
    min-height: 530px;
    & .list-item {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    min-height: 640px;
    & .list-item {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }

`

const SeuImovelGaranteDinheiroCard = ({ sendDataLayerEvent, dataLayerSection, className }: Pick<HomeEquitySectionsProps, 'sendDataLayerEvent' | 'dataLayerSection'> & {className?: string}) => {
  const url = 'https://imobiliario.inter.co/home-equity/?campanha=site-pf?tipoSimulacao=1&valorTotal=70000.00&familia=home_equity&origem=site&gaid=GA1.1.951738362.1715344619#/voce-tem-imovel'
  const handleCtaClick = () => {
    sendDataLayerEvent({
      section: dataLayerSection,
      element_action: 'click button',
      element_name: 'Simular meu empréstimo',
      section_name: 'Seu imóvel te garante mais dinheiro na conta',
      redirect_url: url,
    })
  }
  return (
    <CardWrapper className={className}>
      <H2Citrina
        className='mb-3 mb-lg-4'
        fontSize={{ sm: '22px', md: '28px', lg: '40px', xl: '48px' }}
        lineHeight={{ sm: '24px', md: '32px', lg: '44px', xl: '53px' }}
      >
        Seu imóvel te garante mais dinheiro na conta
      </H2Citrina>
      <InterP
        className='mb-4'
        fontSize={{ sm: '16px', md: '14px', lg: '20px', xl: '18px' }}
        lineHeight={{ sm: '20px', md: '16px', lg: '24px', xl: '22px' }}
      >Com o Home Equity, você aproveita um empréstimo de alto valor, com análise flexível e taxas reduzidas.
      </InterP>
      <ul className='mb-4'>
        <li className='list-item'>
          <IconsSwitch icon='ic_trending_up' lib='interco' customPath='finance/' width='24' height='24' color='#fff' />
          <span>Não é necessário vender ou desocupar seu imóvel;</span>
        </li>
        <li className='list-item'>
          <IconsSwitch icon='ic_piggy_bank' lib='interco' customPath='finance/' width='24' height='24' color='#fff' />
          <span>O imóvel não precisa estar quitado pra ser garantia;</span>
        </li>
        <li className='list-item'>
          <IconsSwitch icon='ic_bank' lib='interco' customPath='travel-mobility/' width='24' height='24' color='#fff' />
          <span>Você pode receber até 60% do valor do seu imóvel;</span>
        </li>
        <li className='list-item'>
          <IconsSwitch icon='ic_boxes' lib='interco' customPath='shopping/' width='24' height='24' color='#fff' />
          <span>Empréstimo exclusivo para imóveis com habite-se.</span>
        </li>
      </ul>
      <div className='d-flex flex-grow-1 align-items-end'>
        <NoDecorationLink
          href={url}
          target='_blank'
          rel='noreferrer'
          onClick={handleCtaClick}
        >
          <Button fullWidth className='btn--white'>
            Simular meu empréstimo
          </Button>
        </NoDecorationLink>
      </div>
    </CardWrapper>
  )
}

export default SeuImovelGaranteDinheiroCard
