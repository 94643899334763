import { device } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

const onTopZIndex = 2
export const Card = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: inherit;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: .4s;
  background: #000;
  padding: 24px 16px;
    @keyframes placeHolderShimmer {
    0% {
      background-position: 0px 0;
    }
    100% {
      background-position: 100em 0;
    }
  }

  &.animated-background {
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    max-height: 100%;
    height: inherit;
    width: 100%;
    position: absolute;
  }

  .card-title,
  .card-subtitle {
    font-family: 'Citrina', Helvetica, sans-serif;
    color: #fff;
    text-align: left;
    margin: 0;
    z-index: ${onTopZIndex};
    position: relative;
  }

  .card-title {
    font-size: 28px;
    line-height: 34px;
  }

  .card-subtitle {
    font-size: 20px;
    line-height: 24px;
  }

  .card-body {
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    z-index: ${onTopZIndex};
    position: relative;
  }

  @media ${device.tablet} {
    height: 393px;
    background-size: 295px;
  }

  @media ${device.desktopLG} {
    height: 462px;
    background-size: 462px;
  }

  @media ${device.desktopXL} {
    height: 560px;
    background-size: 480px;
  }

  @media ${device.desktopXXXL} {
    background-size: 100px;
    width: 420px;
  }

  @keyframes zoom {
    0% {
      background-size: 420px;
    }
    100% {
      background-size: 588px;
    }
  }

  @keyframes reversezoom {
    0% {
      background-size: 588px;
    }
    100% {
      background-size: 420px;
    }
  }

  &.bg {
    animation: zoom .4s ease-in-out forwards;

    &-reverse {
      animation: reversezoom .4s ease-in-out forwards;
    }
  }

  .detail {
    @media ${device.tablet} {
      position: absolute;
      bottom: 33px;
      z-index: 4;
      left: 16px;
    }

    @media ${device.desktopXL} {
      left: 24px;
    }
  }
`

export const CircleWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 36px;
`
export const CircleBtn = styled.button`
  border: none;
  width: 36px;
  height: 36px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${onTopZIndex};

  @media ${device.tablet} {
    width: 34px;
    height: 34px;
  }

  @media ${device.desktopLG} {
    width: 40px;
    height: 40px;
  }

  @keyframes loading {
    0% { transform: rotate(0); }
    100% { transform: rotate(-90deg); }
  }

  @keyframes reverseloading {
    0% { transform: rotate(0); }
    100% { transform: rotate(90deg); }
  }

  &.btn--open {
    background-color: ${orange.extra};
    animation: loading .3s;
  }

  &.btn--close {
    animation: reverseloading .3s;
  }
`
export const CardShadow = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
  top: 0;
  left: 0;
`

export const ContentText = styled.div`
  position: absolute;
  margin-left: 30px;
  padding: 0 20px 0 0;
  margin-top: 300px;

  p, h3 {
    font-family: "Sora", Helvetica, sans-serif;
  }

  @media ${device.tablet} {
    margin-left: 16px;
  }

  @media ${device.desktopLG} {
    margin-top: 350px;
  }

  @media ${device.desktopXL} {
    margin-left: 24px;
    margin-top: 430px;
  }

  &.top {
    margin-top: 120px;

    @media ${device.tablet} {
      margin-top: 80px;
    }

    @media ${device.desktopLG} {
      margin-top: 100px;
    }
  }

  .title {
    font-size: 30px;
    line-height: 37px;
    font-weight: 600;
    color: ${white};
    z-index: 2;
    position: relative;

    @media ${device.tablet} {
      font-size: 20px;
      line-height: 25px;
    }

    @media ${device.desktopLG} {
      font-size: 23px;
      line-height: 29px;
    }

    @media ${device.desktopXL} {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .descritpion {
    margin-top: 34px;
    font-size: 14px;
    line-height: 22px;
    color: ${white};
    z-index: 2;
    position: relative;
    height: 140px;

    @media ${device.tablet} {
      margin-top: 14px;
      font-size: 10px;
      line-height: 20px;
    }

    @media ${device.desktopLG} {
      font-size: 12px;
      line-height: 23px;
    }

    @media ${device.desktopXL} {
      font-size: 16px;
      line-height: 32px;
    }
  }
`

export const Button = styled.button`
  @media ${device.tablet} {
    width: 324px;
  }

  @media ${device.desktopLG} {
    width: 377px !important;
  }

  @media ${device.desktopXL} {
    width: 324px;
  }

  @media ${device.desktopLG} {
    width: 37%;
  }
`

export const ButtonMobile = styled.button`
  width: 312px;
  margin: 0 auto;
`
